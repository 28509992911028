import { Link } from "gatsby"
import * as React from "react"
import GradientJumbo from "../components/gradient-jumbo"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Ops, página não encontrada" lang="pt" noRobots />
    <GradientJumbo
      subtitle="Error 404"
      title="Página não encontrada/Page not found"
    />
    <section className="container">
      <h4 className="h4">
        <Link to="/">← Voltar ao início/Go back</Link>
      </h4>
    </section>
    <section className="container"></section>
    <section className="container"></section>
  </Layout>
)

export default NotFoundPage
